import React from "react"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"
import {Mail} from "react-feather"

const StyledWrapper = styled.footer`
  &.site-footer {
    border-top-style: solid;
    border-top-width: 1px;
    font-size: 0.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    width: 100%;
    background: 0px center;
    list-style: none;
    margin: 2rem 0rem 0rem;
    border-color: #ccc;
  }
`

const Footer = ({siteTitle, url}) => {
  return (
    <StyledWrapper className="site-footer">
      <footer>
        <span>
          Copyright ©&nbsp; {new Date().getFullYear()}{" "}
          <a href={url}>{siteTitle}</a>
        </span>
        <span style={{marginLeft: "14px"}}>
          <span style={{marginRight: "4px"}}>
            <Mail width={14} />
          </span>
          has.winsome@gmail.com
        </span>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="我知道了"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
      >
        為提供您最佳個人化且即時的服務，本網站透過使用Cookies紀錄與存取您的瀏覽使用訊息。當您使用本網站，即表示您同意Cookies技術支援。
      </CookieConsent>
    </StyledWrapper>
  )
}

export default Footer
