import PropTypes from "prop-types"
import React, {useState} from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import styled from "styled-components"

const StyledWrapper = styled.div`
  &.website-header {
    border-color: ${props => props.theme.headerColor};
    > .navbar {
      background-color: ${props => props.theme.headerColor};
    }
    /* change the brand and text color */
    > .navbar .navbar-brand,
    > .navbar .navbar-text {
      color: white;
    }
    /* change the link color */
    > .navbar .navbar-nav .nav-link {
      color: white;
    }
    /* change the color of active or hovered links */
    > .navbar .nav-item.active .nav-link,
    > .navbar .nav-item:hover .nav-link {
      color: #ffffff;
    }

    > .navbar .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    > .navbar.navbar-toggler {
      border-color: #ffffff;
    }
  }
`

const Header = ({siteTitle}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <StyledWrapper className="website-header">
      <Navbar fixed="top" expand="md">
        <div className="container">
          <NavbarBrand href="/">{siteTitle}</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Posts</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/series">Series</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/tags">Tags</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">About</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </StyledWrapper>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
