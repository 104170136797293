import React from "react"
import styled from "styled-components"
import {AlertTriangle, Coffee, Youtube, Facebook} from "react-feather"
import {FacebookProvider, Page} from "react-facebook"
import YoutubeLogo from "../images/yt_logo_rgb_light.png"
import Grids99 from "../images/grids99.png"

const StyledWrapper = styled.div`
  &.site-sidebar {
    .site-sidebar__section {
      /* height: 200px; */
      border: 1px solid #ccc;
      text-align: center;
      margin: 20px;
      padding: 20px 10px;
      > .qrcode,
      .youtube,
      .grids99 {
        max-width: 130px;
        width: 80%;
        margin: auto;
        > a {
          width: inherit;
          img {
            width: inherit;
          }
        }
        p {
          font-size: 10px;
        }
      }
      h7 {
        color: #3a3119;
        font-size: 10px;
      }
      .fb-pages {
        width: 100%;
        overflow: auto;
        iframe {
          width: 100% !important;
        }
      }
    }
  }
`
const Sidebar = ({siteTitle}) => {
  return (
    <StyledWrapper className="site-sidebar">
      {/* <div className="site-sidebar__section">
        <h4>廣告</h4>
        <AlertTriangle size="50" />
        目前還沒有開放
      </div> */}

      <div className="site-sidebar__section">
        <h4>九宮格筆記本</h4>
        <br />
        <div className="grids99">
          <a
            href="https://grids99.winsomeyang.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Grids99} />
          </a>
          <p>winsome開發的小工具</p>
        </div>
      </div>

      <div className="site-sidebar__section">
        <div
          style={{
            fontSize: "12px",
            backgroundColor: "#ffffff",
            width: "100%",
            minHeight: "84px",
            height: "auto",
            textAlign: "left",
            wordBreak: "break-all",
            boxSizing: "border-box",
          }}
        >
          <a
            href="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=8f21a27620b533b3804235807507cdd2977634eb0a2f5a265f7aeac8eaec154934cb8869791bd1c7&cs=7f291cff1d378d9a8bcf0ede37ff9941&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&e=1&url=https%3A%2F%2Fwww.kobo.com%2Ftw%2Fzh%2F"
            target="_blank"
            rel="noopener noreferrer"
            style={{width: "152px"}}
          >
            <img
              src="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=8f21a27620b533b3804235807507cdd2977634eb0a2f5a265f7aeac8eaec154934cb8869791bd1c7&cs=7f291cff1d378d9a8bcf0ede37ff9941&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&url=https%3A%2F%2Fcont001.ias.global.rakuten.com%2Ftw%2Faffl%2Fshoplink%2Fimages%2F17358%2F20190506%2F7989%2FMTUyeDg0.jpg?_ex=152x84"
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "auto",
              }}
              alt="Kobo"
            />
          </a>
          <a
            href="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=8f21a27620b533b3804235807507cdd2977634eb0a2f5a265f7aeac8eaec154934cb8869791bd1c7&cs=7f291cff1d378d9a8bcf0ede37ff9941&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&e=1&url=https%3A%2F%2Fwww.kobo.com%2Ftw%2Fzh%2F"
            title
            style={{color: "#337ab7", margin: "2px", display: "block"}}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
      <div className="site-sidebar__section">
        <div
          style={{
            fontSize: "12px",
            backgroundColor: "#ffffff",
            width: "100%",
            minHeight: "200px",
            height: "auto",
            textAlign: "left",
            wordBreak: "break-all",
            boxSizing: "border-box",
            border: "1px solid #cccccc",
          }}
        >
          <a
            href="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=4c328c2ad99e9313e826af816fea6e1485d4ceab6e134e4b1c29a8769bef1ee851b013d5cf9e1cee&cs=73f4294f274b1e6dbb1c03b871191b2a&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&e=1&url=https%3A%2F%2Fvbtrax.com%2Ftrack%2Fclicks%2F4546%2Fc627c2bf980225d6f18dec35dc2e9753743940c87cefe1f2113ff40863015be8%3Fsubid_1%3D4c328c2ad99e9313e826af816fea6e1485d4ceab6e134e4b1c29a8769bef1ee851b013d5cf9e1cee%26subid_2%3De968235d8b15c2fb%26subid_4%3D73f4294f274b1e6dbb1c03b871191b2a%26subid_3%3D%26t%3Dhttps%253A%252F%252Fwww.udemy.com%252F"
            target="_blank"
            rel="noopener noreferrer"
            style={{width: "200px"}}
          >
            <img
              src="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=4c328c2ad99e9313e826af816fea6e1485d4ceab6e134e4b1c29a8769bef1ee851b013d5cf9e1cee&cs=73f4294f274b1e6dbb1c03b871191b2a&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&url=https%3A%2F%2Fcont001.ias.global.rakuten.com%2Ftw%2Faffl%2Fshoplink%2Fimages%2F21711%2F20200506%2F564%2FVWRlbXkyMDA.png?_ex=200x200"
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "auto",
              }}
              alt="Udemy線上課程"
            />
          </a>
          <a
            href="https://affiliate.api.rakuten.com.tw/redirect?nw=tw&site=afl&ar=4c328c2ad99e9313e826af816fea6e1485d4ceab6e134e4b1c29a8769bef1ee851b013d5cf9e1cee&cs=73f4294f274b1e6dbb1c03b871191b2a&pr=e968235d8b15c2fb&ap=pr%3De968235d8b15c2fb&e=1&url=https%3A%2F%2Fvbtrax.com%2Ftrack%2Fclicks%2F4546%2Fc627c2bf980225d6f18dec35dc2e9753743940c87cefe1f2113ff40863015be8%3Fsubid_1%3D4c328c2ad99e9313e826af816fea6e1485d4ceab6e134e4b1c29a8769bef1ee851b013d5cf9e1cee%26subid_2%3De968235d8b15c2fb%26subid_4%3D73f4294f274b1e6dbb1c03b871191b2a%26subid_3%3D%26t%3Dhttps%253A%252F%252Fwww.udemy.com%252F"
            title
            style={{color: "#337ab7", margin: "2px", display: "block"}}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
      <div className="site-sidebar__section">
        <h4>
          <Youtube /> Youtube頻道
        </h4>
        <br />
        <div className="youtube">
          <a
            href="https://www.youtube.com/channel/UCAzMOk7mDFug5hGlU-ilmGQ/featured?disable_polymer=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={YoutubeLogo} />
          </a>
          <div>
            <h7>謝謝您的訂閱</h7>
          </div>
        </div>
      </div>
      {/* <div className="site-sidebar__section">
        <h4>
          <Facebook /> Fb粉絲團
        </h4>
        <br />
        <div className="fb-pages">
          <FacebookProvider appId="196595768246157">
            <Page
              href="https://www.facebook.com/winsomelearn"
              tabs="timeline"
              height="330"
            />
          </FacebookProvider>
        </div>
      </div> */}
      {
        <div className="site-sidebar__section">
          <h4>
            <Facebook /> Fb粉絲團
          </h4>
          <br />
          <div className="fb-pages">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/winsomelearn"
              data-tabs="timeline"
              data-width="250"
              data-height="400"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/winsomelearn"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/winsomelearn">Winsome在學</a>
              </blockquote>
            </div>
          </div>
        </div>
      }
      <div className="site-sidebar__section">
        <h4>
          <Coffee /> 超級感謝
        </h4>
        <br />
        <div className="qrcode">
          <a
            href="https://p.ecpay.com.tw/D360F"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://payment.ecpay.com.tw/Upload/QRCode/201912/QRCode_4a3e5aa5-11f9-49f4-b01d-6e9fa944decd.png" />
          </a>
          <div>
            <h7></h7>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default Sidebar
